import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/providers/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms/:slug',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'message',
    loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'change-password/:user_id',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'message-inner',
    loadChildren: () => import('./message-inner/message-inner.module').then( m => m.MessageInnerPageModule)
  },
  {
    path: 'no-result',
    loadChildren: () => import('./no-result/no-result.module').then( m => m.NoResultPageModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search-result',
    loadChildren: () => import('./search-result/search-result.module').then( m => m.SearchResultPageModule)
  },
  {
    path: 'thank-you',
    loadChildren: () => import('./thank-you/thank-you.module').then( m => m.ThankYouPageModule)
  },
  /* {
    path: 'artist-funds',
    loadChildren: () => import('./artist/artist-funds/artist-funds.module').then( m => m.ArtistFundsPageModule),
    canActivate: [AuthGuard]
  }, */
  {
    path: 'artist-funds-details',
    loadChildren: () => import('./artist/artist-funds-details/artist-funds-details.module').then( m => m.ArtistFundsDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artist-message',
    loadChildren: () => import('./artist/artist-message/artist-message.module').then( m => m.ArtistMessagePageModule),
    canActivate: [AuthGuard]
  },
  /* {
    path: 'artist-tip-processed',
    loadChildren: () => import('./artist/artist-tip-processed/artist-tip-processed.module').then( m => m.ArtistTipProcessedPageModule),
    canActivate: [AuthGuard]
  }, */
  {
    path: 'artist-login',
    loadChildren: () => import('./artist/artist-login/artist-login.module').then( m => m.ArtistLoginPageModule)
  },
  {
    path: 'artist-profile',
    loadChildren: () => import('./artist/artist-profile/artist-profile.module').then( m => m.ArtistProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artist-message-inner',
    loadChildren: () => import('./artist/artist-message-inner/artist-message-inner.module').then( m => m.ArtistMessageInnerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'message-view',
    loadChildren: () => import('./message-view/message-view.module').then( m => m.MessageViewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artist-message-view',
    loadChildren: () => import('./artist/artist-message-view/artist-message-view.module').then( m => m.ArtistMessageViewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artist-home',
    loadChildren: () => import('./artist/artist-home/artist-home.module').then( m => m.ArtistHomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artist-code',
    loadChildren: () => import('./artist/artist-code/artist-code.module').then( m => m.ArtistCodePageModule)
  },
  {
    path: 'artist-signup',
    loadChildren: () => import('./artist/artist-signup/artist-signup.module').then( m => m.ArtistSignupPageModule)
  },
  {
    path: 'user-share',
    loadChildren: () => import('./shared_components/user-share/user-share.module').then( m => m.UserSharePageModule)
  },
  {
    path: 'instruction',
    loadChildren: () => import('./instruction/instruction.module').then( m => m.InstructionPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'tip-history',
    loadChildren: () => import('./tip-history/tip-history.module').then( m => m.TipHistoryPageModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
