import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-welcome-popover',
  templateUrl: './welcome-popover.component.html',
  styleUrls: ['./welcome-popover.component.scss'],
})
export class WelcomePopoverComponent implements OnInit {

  @Input() showWelcome: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  goTo(path: string) {
    this.navCtrl.navigateRoot(path)
    this.dismiss()
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }
}
