import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventServiceService {

  private dataObserved = new BehaviorSubject<any>('');
  currentEvent = this.dataObserved.asObservable();

  constructor() {}

  setUser(user) {
    this.storeLocally(user)
    this.publish(user)
  }

  storeLocally(user) {
    localStorage.setItem('user_data', JSON.stringify(user));
  }
  
  publish(user):void {
    this.dataObserved.next(user);
  }
  
}
