import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController, NavController } from '@ionic/angular';
import { ConfigService } from 'src/providers/config/config.service';
import { Animation } from '@ionic/core';

interface SpotifyArtist {
  external_urls: {
    spotify: string
  };
  followers: {
    href: string, 
    total: number
  };
  genres: string[];
  href: string;
  id: string;
  images: {
    height: number
    width: number
    url: string
  }[]
  name: string
  popularity: number
  type: string
  uri: string
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  @ViewChild("searchbar") searchbar: IonSearchbar;
  artists: SpotifyArtist[] = []
  searchTerm: string = ""

  constructor(
    private config: ConfigService,
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) { }

  ionViewDidEnter(): void {
    this.searchbar.setFocus();
    setTimeout(() => this.setFocusSearch(), 150)
  }

  ngOnInit() {
  }

  setFocusSearch() {
    this.searchbar.setFocus().then((res) => {
      console.log("Set focus")
    })
  }

  querySearch(event) {
    console.log("Search term: ", this.searchTerm, event)
    if (event.detail.value.length < 3) {
      this.artists = []
      return;
    }

    this.config.getSpotifyArtists(event.detail.value).subscribe((res) => {
      console.log(res)
      this.artists = res.artists.items
      console.log(this.artists)
    })
  }

  chooseArtist(artist: SpotifyArtist) {
    console.log("Choose artist: ", artist)
    const queryParams = {
      album: "Manual_Search",
      artist: artist.name
    }

    this.dismiss()
    this.navCtrl.navigateForward('/search-result',{queryParams});
    
  }

  dismiss() {
    this.modalCtrl.dismiss()
  }

  

}
