import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EventServiceService } from '../event/event-service.service';
import { Storage } from '@capacitor/storage'
import { ɵInternalFormsSharedModule } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private eventService: EventServiceService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let data = localStorage.getItem('user_data');
      console.log("Data", data)
      if(data != null && data !== "undefined") {
        const parsedData = JSON.parse(data);
        if (parsedData.role_id !== 0) {
          console.log("HEre", this.eventService.currentEvent)
          // if (this.eventService.dataObserved.value) {
          //   const user = this.eventService.dataObserved.value
          //   console.log("User: ", user)
          //   if (!user || !this.isSame(user, parsedData)) {
          //     this.eventService.publish(parsedData)
          //   }
            return true
          // } else {
          //   console.log("Else")
          //   this.eventService.publish(parsedData)
          // }
        }
      }
      this.router.navigateByUrl('')
      return false;
  }

  isSame(user, data): boolean {
    console.log(user, data)
    const userKeys = Object.keys(user)
    userKeys.forEach((key) => {
      if (!data[key] || user[key] !== data[key]) {
        return false;
      }
    })

    return true
  }
  
}
