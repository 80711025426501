import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform, NavController, MenuController, ToastController} from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { EventServiceService } from 'src/providers/event/event-service.service';
import { LoadingService } from 'src/providers/loading/loading.service';
import * as CORS from 'cors'
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment'
import { UserService } from '../providers/services/user.service'
import { ConfigService } from 'src/providers/config/config.service';

export const cors = CORS({ origin: true });

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public isLogged = false;
  isArtist = false
  name;
  profile_pic;
  points;
  badge: 'bronze' | 'silver' | 'gold';
  isRemainder = 0;
  data: any = {role_id: 0}

  environment = environment

   public artistPages = [
    {
      title: 'Home',
      url: '/artist-home',
      dir: 'root'
    },
    {
      title: 'Tips',
      url: '/artist-funds-details',
      dir: 'forward'
    },
    {
      title: 'Profile',
      url: '/artist-profile',
      dir: 'forward'
    },
    {
      title: 'Messages',
      url: '/artist-message',
      dir: 'forward'
    }
  ];

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private menu: MenuController,
    public eventService:EventServiceService,
    private ref: ChangeDetectorRef,
    private swUpdate: SwUpdate,
    private userServ: UserService,
    private configServ: ConfigService
  ) {
    this.initializeApp();
    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
      this.splashScreen.hide(); 
      this.checkForUpdate()
      this.checkUserLogin();
    });
  }

  ngOnInit() { }

  checkForUpdate() {
    this.swUpdate.available.subscribe(() => {
      if (confirm('New version available, Load new version?')) {
        window.location.reload()
      }
      /* const toast = this.toastCtrl.create({
        message: 'Update available!',
        buttons: [
          {
            text: 'Update',
            handler: async () => {
              window.location.reload()
            }
          }, {
            text: 'Close',
            handler: () => {
              toast.dismiss()
            }
          }
        ],
        position: 'bottom',
      });

      console.log('update ready', res);

      await toast.present(); */
    });
  }

  async checkUserLogin() {
    let data = localStorage.getItem('user_data');
    console.log("Data", data)
    if(data != null && data !== "undefined") {
      console.log("Data: ", data)
      this.data = JSON.parse(data);

      if(this.data.role_id == 1) {
        console.log("Enabling user menu")
        this.isLogged = true;
        this.isArtist = false;
        this.name = this.data.name;
        this.profile_pic = this.data.profile_pic;
        this.points = this.data.kPoints;
        this.setBadge(this.points)
        
        this.eventService.publish(this.data)
        this.configServ.checkUserToken(this.data.token)
      } else if(this.data.role_id == 2) {
        console.log("Enabling aritst menu", this.data)
        this.isLogged = true;
        this.isArtist = true
        this.name = this.data.name;
        this.profile_pic = this.data.profile_pic;
        this.eventService.publish(this.data)
        this.configServ.checkUserToken(this.data.token)
      }
      this.ref.detectChanges()

    } else {
      this.data.role_id = 0;
      this.isLogged = false;
    }

    this.eventService.currentEvent.subscribe(data => {
      console.log("Data in currentEvent:", data)
      if (data.role_id == 0) {
        this.data = data
        this.isLogged = false;
        this.isArtist = false;
        this.name = ""
        this.profile_pic = "assets/no-image.png"
        this.points = ""
      } else if (data.role_id == 1) {
        this.data = data
        console.log("Role ID: 1")
        this.isLogged = true;
        this.isArtist = false
        this.name = data.name;
        this.profile_pic = data.profile_pic;
        this.points = data.kPoints;
        this.setBadge(this.points)
      } else if(data.role_id == 2) {
        this.data = data
        console.log("ROle ID: 2")
        this.isArtist = true
        this.isLogged = true;
        this.name = data.name;
        this.profile_pic = data.profile_pic;
      }     
      this.ref.detectChanges()
    });
  }

  setBadge(points: number) {
    if (points >= 200 && points < 500) {
      this.badge = "bronze"
    } else if (points >= 500 && points < 1000) {
      this.badge = 'silver'
    } else if (points >= 1000) {
      this.badge = 'gold'
    }
  }

  logout() {
    console.log("Lggging out")
    this.menu.close().then((res) => {
      console.log("Res from close: ", res)
      this.userServ.logout()
      /* this.data = null
      this.profile_pic = 'assets/no-image.png'
      this.isLogged = false;
      this.isArtist = false */
    })
  }

}
