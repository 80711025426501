import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NavigationExtras } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ConfigService } from 'src/providers/config/config.service';
import { EventServiceService } from 'src/providers/event/event-service.service';
import { LoadingService } from 'src/providers/loading/loading.service';

@Component({
  selector: 'app-password-prompt',
  templateUrl: './password-prompt.component.html',
  styleUrls: ['./password-prompt.component.scss'],
})
export class PasswordPromptComponent implements OnInit {

  @Input() email: string;

  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastCtrl: ToastController,
    private config: ConfigService,
    private eventService: EventServiceService,
    private modalCtrl: ModalController,
    private loader: LoadingService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(this.email, Validators.required),
      password: new FormControl('', [Validators.required]),
      type: new FormControl(1),
      fcm: new FormControl('web')
    })
  }

  login() {
    this.loader.show()
    this.config.postData('/login', this.loginForm.value).then(async (res: any) => {
      console.log("Res from login: ", res)
      if(res.success === true) {
        await this.presentToast('Log in successful!');

        localStorage.setItem('user_data', JSON.stringify(res.data));

        this.eventService.publish(res.data);

        this.modalCtrl.dismiss({success: true, userData: res.data})
      } else {
        this.presentToast(res.message);
      }
      
    }).catch((err) => {
      this.presentToast(`An error has occured: ${err.message}`)      
    }).finally(() => this.loader.hide());   
  }

  forgotPassword() {
    this.modalCtrl.dismiss({success: false})
    const queryParams = {
        type: 1,
      }

      const navigationExtras: NavigationExtras = {
        queryParams
      };
    this.navCtrl.navigateForward('/forgot-password', navigationExtras);
  }

  async presentToast(message, duration: number = 3000) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      buttons: [
        {
          text: 'Dismiss',
          handler: () => {
            toast.dismiss()
          }
        }
      ],
      position: 'bottom',
    });

    await toast.present();
  }

  close() {
    this.modalCtrl.dismiss({success: false})
  }
}
