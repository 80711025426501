import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { EventServiceService } from '../event/event-service.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  
  constructor(
    public toastController: ToastController,
    public navCtrl: NavController,
    public eventService: EventServiceService,
    private loader: LoadingService
  ) { }

  logout() {
    this.loader.hide()
    this.toastController.dismiss()
    this.eventService.publish({role_id: 0});

    localStorage.removeItem('user_data')

    this.navCtrl.navigateRoot('/splash');
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

}