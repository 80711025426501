import { Injectable, ResolvedReflectiveFactory } from '@angular/core';
import { HTTP, HTTPResponse } from '@ionic-native/http/ngx';
import { ToastController, Platform } from '@ionic/angular';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public yourSiteUrl =  environment.API_URL;
  public Baseurl: string = this.yourSiteUrl + '/api';
  spotify_token: string;

  constructor(
    //private http:HTTP,
    public httpClient: HttpClient,
    public toastController: ToastController,
    public platform: Platform,
    private userServ: UserService
  ) { 
      this.getSpotifyToken()
    }


  postData(Url,payload, token = '') {
    let nativeHeaders;
    let httpOptions;
    console.log("Token", token)
    if(token === '') {
     nativeHeaders = {
        'Content-Type': 'application/json'
      };

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    } else {
      nativeHeaders = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token ,
      };

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token ,
        })
      };
    }
      return new Promise((resolve, reject) => {
        // if (this.platform.is('cordova')) {
        //     this.http.setDataSerializer('json');
        //     this.http.post(this.Baseurl+Url, payload,nativeHeaders).then(data => {
        //           const d = JSON.parse(data.data);
        //           resolve(d);
        //         }).catch(error => {
        //           console.log('Error : ' + Url);
        //           console.log(error);
        //           console.log(error.error); // error message as string
        //           console.log(error.headers);
        //         });
        // } else {
          console.log("Request headers", httpOptions, "Payload: ", payload)
          this.httpClient.post(this.Baseurl+Url, {...payload, token: token}, httpOptions).subscribe((data: any) => {
            resolve(data);
          }, (err) => {
            // console.log('Error : ' + req);
            reject(err)
            console.error(err);
            this.handleHttpError(err)
          });
        // }
      });
  }

  getData(Url,token = '') {
    let nativeHeaders;
    let httpOptions;

    if(token === '') {
     nativeHeaders = {
        'Content-Type': 'application/json',
      };

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };

    } else {
      nativeHeaders = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
      };

      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+token ,
        })
      };
    }
    return new Promise(resolve => {
      // if (this.platform.is('cordova')) {
      //   this.http.setDataSerializer('json');

      //   this.http.get(this.Baseurl+Url, {},nativeHeaders).then(data => {
      //     const d = JSON.parse(data.data);
      //         resolve(d);
      //       }).catch(error => {
      //         console.log('Error : ' + Url);
      //         console.log(error);
      //         console.log(error.error); // error message as string
      //         console.log(error.headers);
      //       });
      // } else {
        console.log("Get ", Url)

        this.httpClient.get(this.Baseurl+Url, httpOptions).subscribe((data: any) => {
          resolve(data);
        }, (err: HttpErrorResponse) => {
          // console.log('Error : ' + req);
          console.log(err);
          this.handleHttpError(err)
        });
      // }    
    });
  }

  addArtistByAdmin(data: {artist_name: string, album: string}, token: string) {
    return this.postData('/addArtistByAdmin', data, token).then((res) => {
      console.log("Res: ", res)
      return res
    }).catch((err) => {
      console.error("Error: ", err)
      return err
    })
  }

  // postFormData(Url,payload, token = '') {
  //   let nativeHeaders;
  //   nativeHeaders = {
  //     enctype: 'multipart/form-data'
  //   };

  //   return new Promise(resolve => {
  //     this.http.post(this.Baseurl+Url,payload, nativeHeaders)
  //       .then(data => {
  //         console.log(data)
  //         const d = JSON.parse(data.data);
  //         resolve(d);
  //       }).catch(error => {
  //         console.error('Error : ' + Url);
  //         console.error(error);
  //       });
  //   })     
  // }

  checkUserToken(token: string) {
    return this.postData('/checkToken', null, token).catch((err) => {console.error(err)})
  }

  getSpotifyToken() {
    this.getData('/getSpotifyToken').then((res: HTTPResponse) => {
      console.log("Res from get token: ", res, JSON.parse(res.data))
      let resData = JSON.parse(res.data)
      this.spotify_token = resData.access_token
    }).catch((err) => {
      console.error("Error from get token: ", err)
    })
  }

  getSpotifyArtists(searchTerm: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+this.spotify_token
      })
    }

    return this.httpClient.get('https://api.spotify.com/v1/search?query='+searchTerm+'&offset=0&limit=10&type=artist&market=US', httpOptions)
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  handleHttpError(err: HttpErrorResponse) {
    if (err.status === 401) {
      this.userServ.logout()
    }
  }

}

export interface ResObj {
    code: number;
    success: boolean;
    message: string;
    data: any;
}
