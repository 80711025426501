import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: HTMLIonLoadingElement;
  constructor(
    public loadingCtrl: LoadingController
  ) {

  }

  async show(duration: number = 7000, message?: string) {
    this.loading = await this.loadingCtrl.create({
      duration: duration,
      message: message ? message : ''
    });
    this.loading.present();
  }

  async hide() {
    try {
      //this.loadingCtrl.getTop()
      await this.loading.dismiss();
    } catch (error) { }
  }
  async autoHide(time) {
    this.loading = await this.loadingCtrl.create({
      duration: time
    });
    this.loading.present();
  }
}
